window.loaded = false;
var windowWidth = 0;

$(window).on('load', function () {
	window.loaded = true;
});

var homeSlider = undefined;
var sliderType = 'swiper';
var headerBorder = undefined;
var header = undefined;
var needHomeSlider = false;

var infoSection = undefined;
var contactMap = undefined;

var parallaxItems = [];

var homeHeaderTextContent = undefined;
var homeServicesSection = undefined;

$(document).ready(function () {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;
	var resizeFn = function () {
		if (windowWidth != window.innerWidth) {
			windowWidth = window.innerWidth;

			$(document).trigger('pwstudio.window_width_changed', windowWidth);
		}
	}

	window.addEventListener('resize', resizeFn);

	headerBorder = $('[data-purpose="header-scroll-border"]');
	header = $('.main-header');
	if (headerBorder.length === 0) {
		headerBorder = $('.page-header');
	}

	if (headerBorder && headerBorder.length > 0) {
		homeHeaderTextContent = $('[data-purpose="home-header-text"]');
		homeServicesSection = $('[data-purpose="service-section"]');
		var headerScrollFn = function () {
			var height = headerBorder.outerHeight(true) + headerBorder.offset().top;
			var scrollTop = $(window).scrollTop();
			
			if (height <= scrollTop && !header.hasClass('opened-fix-menu')) {
				header.addClass('opened-fix-menu');
				$('body').addClass('opened-fix-menu');
			} else if (height > scrollTop && header.hasClass('opened-fix-menu')) {
				header.removeClass('opened-fix-menu');
				$('body').removeClass('opened-fix-menu');
			}

			if (homeHeaderTextContent && homeHeaderTextContent.length > 0) {
				checkHomeTextPosition();
			} 

			if (homeServicesSection && homeServicesSection.length === 1) {
				checkServicesSection();
			}
		};

		window.addEventListener('touchend', headerScrollFn);
		window.addEventListener('touchmove', headerScrollFn);
		window.addEventListener('scroll', headerScrollFn);
	}

	$('.mailtolink').click(function (e) {
		handleMailtoLink($(this));
	});

	$('[data-purpose="navigation-link"]').each(function () {
		setNavigationUrl($(this));
	});

	$('[data-purpose="remodal-open"]').click(function () {
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});

	$('[data-purpose="remodal-close"]').click(function () {
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function () {
		var target = $(this).data('target');
		var item = $(target);
		var timeout = $(this).data('timeout');
		if (isNaN(timeout)) {
			timeout = 400;
		}

		if (item.length > 0) {
			var top = item.offset().top;
			if (header && header.length == 1) {
				top -= (header.outerHeight(true) + 30);
			}
			$('html, body').animate({
				scrollTop: top
			}, timeout);
		}
	});

	$('[data-purpose="remodal-open"]').click(function () {
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function () {
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});


	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			if (needHomeSlider) {
				switch (sliderType) {
					case 'bxslider':
						initHomeBxSlider();
						break;
					case 'swiper':
						initHomeSwiperSlider();
						break;
				}
			}

			$('[data-purpose="progress-item"]').each(function () {
				new CircleProgressBar($(this));
			});

			progressBars = $('[data-purpose="progress-item"]');
			if (progressBars && progressBars.length > 0) {
				var start = checkHomeCircleProgressBar();

				if (!start) {
					$(window).on('scroll.progressbar-check touchend.progressbar-check touchmobe.progressbar-check resize.progressbar-check', function () {
						var done = checkHomeCircleProgressBar();

						if (done) {
							$(window).off('scroll.progressbar-check touchend.progressbar-check touchmobe.progressbar-check resize.progressbar-check');
						}
					});
				}
			}
		} else if (site_id == 2) {
			initMap();
		}
	}

	$('select').change(function () {
		var value = parseInt($(this).val());

		if (!value && $(this).hasClass('has-value')) {
			$(this).removeClass('has-value');
		} else if (value && !$(this).hasClass('has-value')) {
			$(this).addClass('has-value');
		}
	}).change();

	$('.accordion-list').each(function (ind, el) {

		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));

		new Accordion(th, options);
	});

	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="reference-gallery-item"]').click(function () {
			openGallery($(this));
		});
	}

	$('[data-purpose="close-hello-bar"]').click(function () {
		closeHelloBar($(this));
	});

	$('[data-purpose="parallax-content"]').each(function(){
		if (isTouchDevice()) {
			$(this).addClass('touch-device');
		}
		
		parallaxItems.push($(this));
	});
	
	if (parallaxItems.length > 0 && !isTouchDevice()) {
		$(window).on('scroll', function(){
			var scrollTop = $(window).scrollTop();
            var windowHeight = window.innerHeight;
            var windowWidth = window.innerWidth;
            
            var currentBottom = scrollTop + windowHeight;

            var max = windowWidth * 0.23;
            if (max > 100) {
                max = 100;
            }
            var min = max * -1;
            
            for (var i = 0; i < parallaxItems.length; i++) {
                var item = parallaxItems[i];
				
                if (windowWidth >= 600) {
                    var parent = item.parent();
                    var top = parent.offset().top;
                    var height = parent.outerHeight(true);
                    var endPosition = top+height;
                    
                    if (top <= currentBottom && endPosition >= scrollTop) {
                        var parallaxVal = (scrollTop - top) / 8;
                        
                        if (parallaxVal < min) {
                            parallaxVal = min;
                        }

                        if (parallaxVal > max) {
                            parallaxVal = max;
                        }
                        
                        item.attr('style', 'transform:translate3d(0px, '+parallaxVal+'px, 0px);');
                    }
                } else {
                    item.removeAttr('style');
                }
            }
		});
	}
});

function checkHomeCircleProgressBar() {
	var done = false;
	var progressStartBorder = 0;

	progressBars.each(function () {
		var tmp = $(this).offset().top + ($(this).outerHeight() * 0.66);
		if (!progressStartBorder || tmp < progressStartBorder) {
			progressStartBorder = tmp;
		}
	});


	var currentPosition = $(window).innerHeight() + $(window).scrollTop();

	if (currentPosition > progressStartBorder) {
		$(document).trigger('pwstudio.play_circle_progress_bar');
		done = true;
	}

	return done;
}

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="' + remodal_id + '"]');
	if (tmp.length > 0) {
		tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}

		gtag('event', action, evtData);
	} else if (typeof ga != 'undefined' && action && category) {
		if (tag && tag.trim() != '') {
			ga('send', 'event', action, category, tag);
		} else {
			ga('send', 'event', action, category);
		}
	}
}

function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode) {
	var scriptTag = document.createElement('script');
	scriptTag.src = url;

	scriptTag.onload = implementationCode;
	scriptTag.onreadystatechange = implementationCode;

	document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');

	if (address) {
		var protocol = 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if (device == 'iphone' || device == 'ipad' || device == 'ipod') {
			function iOSversion() {

				if (/iP(hone|od|ad)/.test(navigator.platform)) {
					var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
					return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}

		var urlSuffix = '/?daddr=' + address;
		var nav_url = '';
		switch (protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url + urlSuffix);
	}
}

function initHomeBxSlider() {
	var touchDevice = isTouchDevice();
	

	homeSlider = $('#homeSlider').bxSlider({
		auto: true,
		pause: 5000,
		infiniteLoop: true,
		touchEnabled: touchDevice,
		preventDefaultSwipeX: true,
		preventDefaultSwipeY: false,
		controls: false,
		mode: 'fade',
		speed: 1000,
		pager: false,
	});

	if (typeof LazyLoad !== 'undefined') {
		var list = document.getElementById('homeSlider');
		var items = list.getElementsByTagName('li');
		for (var i = 0; i < items.length; i++) {
			LazyLoad.load(items[i]);
		}
	}
}

function isTouchDevice() {
	var touchDevice = false;
	if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)) {
		touchDevice = true;
	}

	return touchDevice;
}

function initHomeSwiperSlider() {
	homeSlider = new Swiper('#homeSlider', {
		effect: 'fade',
		autoplay: {
			delay: 20000
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		}
	});
}


function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');

	if (typeof mediaGroupItems === 'object' && typeof mediaGroupItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, mediaGroupItems[groupId], index);
	}
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) {
	if (!index) {
		index = 0;
	}

	$(target).lightGallery({
		dynamic: true,
		dynamicEl: items,
		index: index,
		hash: false,
		download: false
	});
}

function initGalleries() {
	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="gallery-box-item"]:not(.initialized)').each(function () {
			$(this).addClass('initialized');
			$(this).click(function () {
				var id = $(this).data('group-id');
				if (id && typeof mediaGroupItems[id] !== 'undefined') {
					initGallery($(this), mediaGroupItems[id]);
				}
			});
		});
	}
}

function closeHelloBar(btn) {
	var parent = btn.parent();
	if (parent && !parent.hasClass('closed')) {
		parent.addClass('closed');
		parent.slideToggle();

		$.ajax({
			url: ajax_controller + 'closeHelloBar',
			dataType: 'json',
			type: 'post'
		});
	}
}

function initMap() {
	if (typeof mapboxgl !== 'undefined' && mapboxgl.supported()) {
		mapboxgl.accessToken = 'pk.eyJ1Ijoic2VydWthIiwiYSI6ImNqNmhxbjF2ejA5YXAzMnRlN3o4ZjBhdTQifQ.Wj8inzuV9QHNCovlvPucSg';

		contactMap = new mapboxgl.Map({
			container: 'contactMap',
			style: 'mapbox://styles/seruka/ckd9trtfq0kg41iogn52quue6',
			center: this.getMapCenter(),
			zoom: 14.2
		});

		contactMap.scrollZoom.disable();

		contactMap.on('load', function () {
			var el = document.createElement('div');
			el.className = 'map-marker';
			el.innerHTML = '<span class="marker-outer"><img src="' + base_url + 'assets/img/svg/marker.svg" alt="marker" /></span>';

			new mapboxgl.Marker(el)
				.setLngLat([21.63695, 47.49616])
				// .setLngLat(getMapCenter())
				.addTo(contactMap);
		});
	} else {
		$('.mapbox-section').addClass('unsupported');
	}
}

function getMapCenter() {
	var width = window.innerWidth;

	if (width <= 600) {
		return [21.63745, 47.49664];
	} else {
		return [21.62713, 47.49614];
	}
}

function checkHomeTextPosition() {
	if (homeHeaderTextContent && homeHeaderTextContent.length === 1) {
		var section = homeHeaderTextContent.parent();
		var sectionHeight = section.outerHeight();
		var top = sectionHeight * 0.2;
		var scrollTop = $(window).scrollTop();
		var style = homeHeaderTextContent.attr('style');
		if (scrollTop > top) {
			var diff = (scrollTop - top) * 0.43;
			if (diff < 0) {
				diff = 0;
			}
			var percent = diff / scrollTop;
			var opacity = 1 - percent;
			
			if (opacity > 1) {
				opacity = 1;
			} else if (opacity < 0) {
				opacity = 0;
			}

			diff *= -1;
			homeHeaderTextContent.attr('style', 'transform:translate3d(0px, '+diff+'px, 0px);opacity:'+opacity+';)');
		} else if(style) {
			homeHeaderTextContent.removeAttr('style');
		}
	}
}

function checkServicesSection() {
	if (homeServicesSection && homeServicesSection.length === 1 && !homeServicesSection.hasClass('loaded')) {
		var scrollTop = $(window).scrollTop();
		var height = window.innerHeight;
		var top = homeServicesSection.find('.services-content').offset().top;
		var serviceHeight = homeServicesSection.find('.service-item').eq(0).outerHeight();
		
		var sens = serviceHeight * 0.4;
		
		if (scrollTop+height >= top + sens) {
			homeServicesSection.addClass('loaded');
		}
	}
}
