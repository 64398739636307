(function(wnd){
    function CircleProgressBar(item) {
        try {
            if (!item) {
                throw 'Item is undefined';
            }

            this.item = item;

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    CircleProgressBar.prototype = {
        item: undefined,
        interval: undefined,
        suffix: '',
        dasharray:0,
        maxValue: 0,
        currentValue: 0,
        circle: undefined,
        textContent: '',
        speed: 2000,
        counter: undefined,
        step: 1,
        small: false,
        currentDasharray: 0,
        startEvent: 'event',
        currentSize:0,
        currentBreakpoint: 0,
        sizes: {
            small: {
                '1': 92,
                '642': 160,
                '1120':232
            }, 
            big: {
                '1':92,
                '642':180,
                '1120':252
            }
        },
        breakpoints: [1120, 642, 1],
        init: function() {
            this.setElements();

            if (this.maxValue) {
                this.setCircleSize();

                this.addDOMEvents();
            }
        },
        setElements: function() {
            this.circle = this.item.find('circle');
            
            if (this.circle && this.circle.length == 1) {
                this.maxValue = parseInt(this.item.data('value'));
                this.suffix = this.item.data('suffix');
                this.counter = this.item.find('.counter');
                this.small = this.item.hasClass('small');
                
                var step = this.item.data('step');
                if (step && !isNaN(step)) {
                    this.step = parseInt(step);
                }

                this.currentDasharray = this.circle.attr('stroke-dasharray');
            }
        },
        addDOMEvents: function() {
            var _self = this;

            switch (this.startEvent) {
                case 'load':
                    setTimeout(function(){
                        _self.play();
                    }, 500);
                    break;
                case 'event':
                    $(document).on('pwstudio.play_circle_progress_bar', function(){
                        _self.play();
                    });
                    break;
            }



            $(document).on('pwstudio.window_width_changed', function(e, newWidth){
                _self.setCircleSize();
            })
        },
        play: function() {
            var _self = this;
            var timeout = this.speed / this.maxValue;
            
            this.interval = setInterval(function() {
                _self.incrementValue();
            }, timeout);
        },
        incrementValue: function(){
            this.currentValue += this.step;
            if (this.currentValue >= this.maxValue) {
                this.currentValue = this.maxValue;
                clearInterval(this.interval);
                this.counter.html(this.maxValue+(this.suffix ? '<span>'+this.suffix+'</span>' : ''));
            } else {
                this.counter.html(this.currentValue);
            }

            var percent = this.currentValue / this.maxValue;
            var dasharray = this.currentDasharray - (this.currentDasharray * percent);
            this.circle.attr('stroke-dashoffset', dasharray);
        },
        setCircleSize: function() {
            // console.log(this.circle);
            var breakpoint = this.getBreakpoint();
            
            if (breakpoint != this.currentBreakpoint) {
                this.currentBreakpoint = breakpoint;
                var key = this.small ? 'small' : 'big';
                var width = this.sizes[key][breakpoint];
                var radius = width / 2;
                this.circle.attr('cx', radius);
                this.circle.attr('cy', radius);
                this.circle.attr('r', radius);

                var pi = Math.PI;
                var dasharray = Math.ceil(radius * 2 * pi);
                this.circle.attr('stroke-dasharray', dasharray);
                this.currentDasharray = dasharray;

                if (this.currentValue) {
                    var percent = this.currentValue / this.maxValue;
                    this.circle.attr('stroke-dashoffset', this.currentDasharray - (this.currentDasharray * percent));
                }
            }
        },
        getBreakpoint: function() {
            var breakpoint = undefined;
            for (var i = 0; i < this.breakpoints.length; i++) {
                // console.log(this.breakpoints[i], window.innerWidth);
                if (parseInt(this.breakpoints[i]) <= window.innerWidth) {
                    breakpoint = this.breakpoints[i];
                    break;
                }
            }

            return breakpoint;
        }
    };

    wnd.CircleProgressBar = CircleProgressBar;
})(window);
